<template>
  <div class="preloader"><div class="spinner"></div></div>
  <!-- /.preloader -->
  <Header />
  <InnerHeaderContent  bgimage="applicationform.jpg"/>
  <!-- /.count-down-style-one-wrapper -->

  <section class="feature-style-one sec-pad">
    <div class="thm-container">
      <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="single-feature-style-one">
            <a href="#"><h3>What Is Bitcoin</h3></a>
            <p>
              Bitcoin is changing the way people <br />
              think about money. Educate yourself <br />
              about this ground-breaking <br />
              payment system.
            </p>
          </div>
          <!-- /.single-feature-style-one -->
        </div>
        <!-- /.col-md-4 -->
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="single-feature-style-one">
            <a href="#"><h3>How It Works</h3></a>
            <p>
              Bitcoin is changing the way people <br />
              think about money. Educate yourself <br />
              about this ground-breaking <br />
              payment system.
            </p>
          </div>
          <!-- /.single-feature-style-one -->
        </div>
        <!-- /.col-md-4 -->
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="single-feature-style-one">
            <a href="#"><h3>What Is Benefit</h3></a>
            <p>
              Bitcoin is changing the way people <br />
              think about money. Educate yourself <br />
              about this ground-breaking <br />
              payment system.
            </p>
          </div>
          <!-- /.single-feature-style-one -->
        </div>
        <!-- /.col-md-4 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.feature-style-one -->

  <section class="feature-style-two sec-pad gray-bg">
    <div class="thm-container">
      <div class="row">
        <div class="col-md-5 col-sm-12 col-xs-12">
          <div class="feature-style-two-title">
            <span>Why People Choose Us?</span>
            <h3>
              It’s the dawn of <br />a better, more <br />
              free world.
            </h3>
            <a href="#" class="thm-btn yellow-bg-hvr">Learn More</a>
          </div>
          <!-- /.feature-style-two-title -->
        </div>
        <!-- /.col-md-6 -->
        <div class="col-md-7 col-sm-12 col-xs-12">
          <div class="feature-style-two-content">
            <div class="row feature-style-two-content-row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-feature-style-two">
                  <div class="top-box">
                    <div class="icon-box">
                      <i class="bitmex-icon-shield"></i>
                    </div>
                    <!-- /.icon-box -->
                    <div class="text-box">
                      <h3>
                        Safe and <br />
                        secure
                      </h3>
                    </div>
                    <!-- /.text-box -->
                  </div>
                  <!-- /.top-box -->
                  <p>
                    Lorem ipsum dolor sit a consetetur sadipscing elitr sed diam
                    nonumy eirmod tempor invidunt.
                  </p>
                </div>
                <!-- /.single-feature-style-two -->
              </div>
              <!-- /.col-md-6 -->
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-feature-style-two">
                  <div class="top-box">
                    <div class="icon-box">
                      <i class="bitmex-icon-handshake"></i>
                    </div>
                    <!-- /.icon-box -->
                    <div class="text-box">
                      <h3>
                        Trusted since <br />
                        2005
                      </h3>
                    </div>
                    <!-- /.text-box -->
                  </div>
                  <!-- /.top-box -->
                  <p>
                    Lorem ipsum dolor sit a consetetur sadipscing elitr sed diam
                    nonumy eirmod tempor invidunt.
                  </p>
                </div>
                <!-- /.single-feature-style-two -->
              </div>
              <!-- /.col-md-6 -->
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-feature-style-two">
                  <div class="top-box">
                    <div class="icon-box">
                      <i class="bitmex-icon-clock"></i>
                    </div>
                    <!-- /.icon-box -->
                    <div class="text-box">
                      <h3>
                        24/7 experts <br />
                        support
                      </h3>
                    </div>
                    <!-- /.text-box -->
                  </div>
                  <!-- /.top-box -->
                  <p>
                    Lorem ipsum dolor sit a consetetur sadipscing elitr sed diam
                    nonumy eirmod tempor invidunt.
                  </p>
                </div>
                <!-- /.single-feature-style-two -->
              </div>
              <!-- /.col-md-6 -->
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-feature-style-two">
                  <div class="top-box">
                    <div class="icon-box">
                      <i class="bitmex-icon-coin"></i>
                    </div>
                    <!-- /.icon-box -->
                    <div class="text-box">
                      <h3>
                        Instant currency <br />
                        exchange
                      </h3>
                    </div>
                    <!-- /.text-box -->
                  </div>
                  <!-- /.top-box -->
                  <p>
                    Lorem ipsum dolor sit a consetetur sadipscing elitr sed diam
                    nonumy eirmod tempor invidunt.
                  </p>
                </div>
                <!-- /.single-feature-style-two -->
              </div>
              <!-- /.col-md-6 -->
            </div>
            <!-- /.row -->
          </div>
          <!-- /.feature-style-two-content -->
        </div>
        <!-- /.col-md-6 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.feature-style-two -->

  <section class="sec-pad blog-style-one">
    <div class="thm-container">
      <div class="sec-title text-center">
        <span>updates from blog</span>
        <h3>Latest News</h3>
      </div>
      <!-- /.sec-title text-center -->
      <div class="row masonary-layout">
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-blog-style-one">
            <div class="img-box">
              <img src="img/blog-1-1.jpg" alt="Awesome Image" />
              <a href="#" class="read-more">+</a>
            </div>
            <!-- /.img-box -->
            <div class="text-box">
              <div class="meta-info">
                <a href="#"><i class="fa fa-user"></i> by admin</a>
                <a href="#"><i class="fa fa-comments"></i> 3 Comments</a>
              </div>
              <!-- /.meta-info -->
              <a href="#"
                ><h3>
                  Calculating bitcoins <br />
                  mining profitability
                </h3></a
              >
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-blog-style-one -->
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-blog-style-one">
            <div class="img-box">
              <img src="img/blog-1-2.jpg" alt="Awesome Image" />
              <a href="#" class="read-more">+</a>
            </div>
            <!-- /.img-box -->
            <div class="text-box">
              <div class="meta-info">
                <a href="#"><i class="fa fa-user"></i> by admin</a>
                <a href="#"><i class="fa fa-comments"></i> 3 Comments</a>
              </div>
              <!-- /.meta-info -->
              <a href="#"
                ><h3>
                  Learn what is bitcoin <br />
                  mining pool?
                </h3></a
              >
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-blog-style-one -->
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-blog-style-one">
            <div class="img-box">
              <img src="img/blog-1-3.jpg" alt="Awesome Image" />
              <a href="#" class="read-more">+</a>
            </div>
            <!-- /.img-box -->
            <div class="text-box">
              <div class="meta-info">
                <a href="#"><i class="fa fa-user"></i> by admin</a>
                <a href="#"><i class="fa fa-comments"></i> 3 Comments</a>
              </div>
              <!-- /.meta-info -->
              <a href="#"
                ><h3>
                  How to get started with <br />
                  bitcoin
                </h3></a
              >
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-blog-style-one -->
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-blog-style-one">
            <div class="img-box">
              <img src="img/blog-1-4.jpg" alt="Awesome Image" />
              <a href="#" class="read-more">+</a>
            </div>
            <!-- /.img-box -->
            <div class="text-box">
              <div class="meta-info">
                <a href="#"><i class="fa fa-user"></i> by admin</a>
                <a href="#"><i class="fa fa-comments"></i> 3 Comments</a>
              </div>
              <!-- /.meta-info -->
              <a href="#"
                ><h3>
                  Bitcoin whitepaper: A <br />
                  beginner’s guide
                </h3></a
              >
            </div>
            <!-- /.text-box -->
          </div>
          <!-- /.single-blog-style-one -->
        </div>
        <!-- /.col-md-3 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.sec-pad blog-style-one -->

  <section class="btc-calculator sec-pad">
    <div class="thm-container">
      <div class="sec-title text-center black">
        <span>Bitcoin currency</span>
        <h3>Calculator</h3>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy <br />
          eirmod tempor invidunt ut laboret dolore magna aliquyam erat.
        </p>
      </div>
      <!-- /.sec-title -->
      <form action="#" class="btc-calc-form row">
        <div class="col-md-3">
          <input type="text" placeholder="enter amount" />
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3">
          <select class="selectpicker">
            <option value="#">Bitcoind ( BTC )</option>
            <option value="#">Bitcoind ( BTC )</option>
            <option value="#">Bitcoind ( BTC )</option>
            <option value="#">Bitcoind ( BTC )</option>
            <option value="#">Bitcoind ( BTC )</option>
            <option value="#">Bitcoind ( BTC )</option>
          </select>
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3">
          <input type="text" placeholder="enter amount" />
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3">
          <select class="selectpicker">
            <option value="#">US Dollars</option>
            <option value="#">US Dollars</option>
            <option value="#">US Dollars</option>
            <option value="#">US Dollars</option>
            <option value="#">US Dollars</option>
            <option value="#">US Dollars</option>
          </select>
        </div>
        <!-- /.col-md-3 -->
      </form>
      <!-- /.btc-calc-form -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.btc-calculator -->

  <section class="cta-style-one sec-pad">
    <div class="thm-container">
      <div class="row">
        <div class="col-md-5">
          <img src="img/cta-moc-1.png" alt="Awesome Image" />
        </div>
        <!-- /.col-md-5 -->
        <div class="col-md-7">
          <div class="cta-style-one-content">
            <span>The Bitcoin Wallet on Phones</span>
            <h3>
              Get Mobile Application <br />
              & Start Earning
            </h3>
            <p>
              Lorem ipsum dolor sit a consetetur sadipscing elitr sed diam
              nonumy eirmod tempor invidunt dolor sit amet, legere explicari vis
              in scaevola reprimique eloquentiam at eoso labitur repudiare usu.
            </p>
            <div class="btn-box">
              <a href="#" class="cta-one-btn">
                <span class="icon-box">
                  <i class="fa fa-apple"></i>
                </span>
                <span class="text-box">
                  <span class="label-text">Download on the</span>
                  <span class="title-text">Apple Store</span>
                </span>
              </a>
              <a href="#" class="cta-one-btn style-two">
                <span class="icon-box">
                  <i class="fa fa-android"></i>
                </span>
                <span class="text-box">
                  <span class="label-text">Download on the</span>
                  <span class="title-text">Google Play</span>
                </span>
              </a>
            </div>
            <!-- /.btn-box -->
          </div>
          <!-- /.cta-style-one-content -->
        </div>
        <!-- /.col-md-7 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.cta-style-one -->

  <section class="fun-fact-style-one dark-bg sec-pad">
    <div class="thm-container">
      <div class="row">
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-fun-fact-style-one text-center">
            <i class="bitmex-icon-coin"></i>
            <p>Total Transactions</p>
            <h3 class="counter">8980</h3>
          </div>
          <!-- /.single-fun-fact-style-one text-center -->
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-fun-fact-style-one text-center">
            <i class="bitmex-icon-wallet"></i>
            <p>Bitcoin Wallets</p>
            <h3 class="counter">7540</h3>
          </div>
          <!-- /.single-fun-fact-style-one text-center -->
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-fun-fact-style-one text-center">
            <i class="bitmex-icon-like"></i>
            <p>Happy Users</p>
            <h3 class="counter">9260</h3>
          </div>
          <!-- /.single-fun-fact-style-one text-center -->
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-fun-fact-style-one text-center">
            <i class="bitmex-icon-handshake"></i>
            <p>Bitcoin Investors</p>
            <h3 class="counter">5721</h3>
          </div>
          <!-- /.single-fun-fact-style-one text-center -->
        </div>
        <!-- /.col-md-3 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.fun-fact-style-one -->

  <section class="testimonials-style-one sec-pad">
    <div class="thm-container">
      <div class="sec-title text-center">
        <span>what people say</span>
        <h3>Testimonials</h3>
      </div>
      <!-- /.sec-title text-center -->
      <div class="testimonials-style-one-carousel owl-theme owl-carousel">
        <div class="item">
          <div class="single-testimonials-style-one">
            <p>
              There are many variations of passages of available but the
              majority have suffer alteration in some form by inject humour or
              random words which don't look even slightly believe you are going
              middle of text.
            </p>
            <h3>Margert Heuvelmann</h3>
            <span>Web Developer</span>
            <img src="img/testi-1-1.png" alt="Awesome Image" />
          </div>
          <!-- /.single-testimonials-style-one -->
        </div>
        <!-- /.item -->
        <div class="item">
          <div class="single-testimonials-style-one">
            <p>
              There are many variations of passages of available but the
              majority have suffer alteration in some form by inject humour or
              random words which don't look even slightly believe you are going
              middle of text.
            </p>
            <h3>Jerrell Weatherby</h3>
            <span>Web Developer</span>
            <img src="img/testi-1-2.png" alt="Awesome Image" />
          </div>
          <!-- /.single-testimonials-style-one -->
        </div>
        <!-- /.item -->
        <div class="item">
          <div class="single-testimonials-style-one">
            <p>
              There are many variations of passages of available but the
              majority have suffer alteration in some form by inject humour or
              random words which don't look even slightly believe you are going
              middle of text.
            </p>
            <h3>Gregory Garczynski</h3>
            <span>Web Developer</span>
            <img src="img/testi-1-3.png" alt="Awesome Image" />
          </div>
          <!-- /.single-testimonials-style-one -->
        </div>
        <!-- /.item -->
      </div>
      <!-- /.testimonials-style-one-carousel -->
    </div>
    <!-- /.thm-container -->
  </section>
  <!-- /.testimonials-style-one -->

  <div class="footer-top">
    <div class="thm-container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
          <div class="single-footer-top">
            <p>
              <i class="bitmex-icon-envelope"></i
              ><span>Email: </span>inquiry@bitcoin.com
            </p>
          </div>
          <!-- /.single-footer-top -->
        </div>
        <!-- /.col-md-4 -->
        <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
          <div class="single-footer-top">
            <p>
              <i class="bitmex-icon-phone-call"></i><span>Call: </span>2800 256
              508
            </p>
          </div>
          <!-- /.single-footer-top -->
        </div>
        <!-- /.col-md-4 -->
        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <div class="single-footer-top">
            <p>
              <i class="bitmex-icon-placeholder"></i><span>Address: </span>Suite
              20 Bitcoin Street West USA
            </p>
          </div>
          <!-- /.single-footer-top -->
        </div>
        <!-- /.col-md-4 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </div>
  <!-- /.footer-top -->

  <footer class="site-footer">
    <div class="thm-container">
      <div class="row masonary-layout">
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-footer-widget">
            <div class="title">
              <h3>About</h3>
            </div>
            <!-- /.title -->
            <ul class="links-list">
              <li><a href="#">Home</a></li>
              <li><a href="#">Jobs</a></li>
              <li><a href="#">Our Team</a></li>
              <li><a href="#">Contact Us</a></li>
              <li><a href="#">Latest News</a></li>
              <li><a href="#">Free Consultancy</a></li>
            </ul>
            <!-- /.links-list -->
          </div>
          <!-- /.single-footer-widget -->
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-footer-widget">
            <div class="title">
              <h3>Bitcoin</h3>
            </div>
            <!-- /.title -->
            <ul class="links-list">
              <li><a href="#">Get Started</a></li>
              <li><a href="#">Wallets</a></li>
              <li><a href="#">Buy & Sell Bitcoins</a></li>
              <li><a href="#">Exchange Market</a></li>
              <li><a href="#">Daily Stats</a></li>
            </ul>
            <!-- /.links-list -->
          </div>
          <!-- /.single-footer-widget -->
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-footer-widget">
            <div class="title">
              <h3>Legal</h3>
            </div>
            <!-- /.title -->
            <ul class="links-list">
              <li><a href="#">Guide</a></li>
              <li><a href="#">FAQ</a></li>
              <li><a href="#">Advertise</a></li>
              <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Terms of Use</a></li>
            </ul>
            <!-- /.links-list -->
          </div>
          <!-- /.single-footer-widget -->
        </div>
        <!-- /.col-md-3 -->
        <div class="col-md-3 col-sm-6 col-xs-12">
          <div class="single-footer-widget">
            <div class="title">
              <h3>Subscribe</h3>
            </div>
            <!-- /.title -->
            <form action="inc/mailchimp/subscribe.php" class="subscribe-form">
              <input type="text" placeholder="Email Address" />
              <button type="submit" class="fa fa-check"></button>
            </form>
            <!-- /.subscribe-form -->
          </div>
          <!-- /.single-footer-widget -->
        </div>
        <!-- /.col-md-3 -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.thm-container -->
  </footer>
  <!-- /.site-footer -->

  <div class="footer-bottom">
    <div class="thm-container clearfix">
      <div class="pull-left copy-text">
        <p>&copy; 2018 Bitcoin.com All copy rights are reserved.</p>
      </div>
      <!-- /.pull-left copy-text -->
      <div class="social pull-right">
        <a href="#" class="fa fa-twitter"></a><!-- -->
        <a href="#" class="fa fa-facebook"></a><!---->
        <a href="#" class="fa fa-youtube-play"></a><!---->
        <a href="#" class="fa fa-pinterest"></a>
      </div>
      <!-- /.social -->
    </div>
    <!-- /.thm-container -->
  </div>
  <!-- /.footer-bottom -->

  <div class="search_area zoom-anim-dialog mfp-hide" id="test-search">
    <div class="search_box_inner">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Search for..." />
        <span class="input-group-btn">
          <button class="btn btn-default" type="button">
            <i class="bitmex-icon-search"></i>
          </button>
        </span>
      </div>
    </div>
  </div>

  <section class="hidden-sidebar side-navigation">
    <a href="#" class="close-button side-navigation-close-btn fa fa-times"></a
    ><!-- /.close-button -->
    <div class="sidebar-content">
      <h3>
        Crypto Bitcoin <br />
        Currency <br />
        Html Template
      </h3>
      <p>
        Lorem ipsum dolor sit amet adipiscing elitn quis ex et mauris vulputate
        semper Etiam eget lacus dapibs ultricies diam vel sollicitudin.
      </p>
      <p class="contact-info">
        Inquiry@bitcoin.com <br />
        2800 256 508
      </p>
      <!-- /.contact-info -->
      <div class="social">
        <a href="#" class="fa fa-twitter"></a><!---->
        <a href="#" class="fa fa-facebook"></a><!---->
        <a href="#" class="fa fa-youtube-play"></a><!---->
        <a href="#" class="fa fa-pinterest"></a>
      </div>
      <!-- /.social -->
    </div>
    <!-- /.sidebar-content -->
  </section>
  <!-- /.hidden-sidebar -->

  <div class="scroll-to-top scroll-to-target" data-target="html">
    <i class="fa fa-angle-up"></i>
  </div>
</template>

<script>
// @ is an alias to /src
import InnerHeaderContent from "@/components/InnerHeaderContent.vue";
import Header from "@/components/Header.vue";

export default {
  name: "About",
  components: {
    InnerHeaderContent,
    Header,
  },
  mounted() { 
    this.$store.commit('About');
  },
};
</script>
